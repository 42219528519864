import React from 'react'
import { useState, useEffect } from 'react';
import Product from '../components/Product';
import Spinner from '../components/Spinner';

const Home = () => {

  const API_URL = 'https://fakestoreapi.com/products';
  const [loading, setLoading] = useState(false);
  const [items, setItems] = useState([]);

  async function fetchProductData() {
    setLoading(true);

    try{
        const res = await fetch(API_URL);
        const data = await res.json();

        setItems(data);
    }
    catch(error) {
        console.log("Error fetching product");
        setItems([]);
    }   
    setLoading(false);
  }

  useEffect( () => {
    fetchProductData();
  },[])

  return (
    <div class='screen-1 '>
      <div class="screen-1-wrapper ">
        {
          loading ? <Spinner /> : 
          items.length > 0 ? 
          (<div className="">
              {
                items.map( (item) => (
                    <Product key = { item.id } item={item} />
                  ) )
              }
            <div style={{height:'150px'}}></div>
          </div>) : 
        
        <div className="flex justify-center items-center "> 
            <p className='text-gray-700 font-semibold text-lg text-left truncate w-40 mt-[10%]'>No Data Found !</p>
          </div>

        }
      </div>
    </div>
  );
}

export default Home