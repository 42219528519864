import React from 'react'
import { useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { click } from '@testing-library/user-event/dist/click';
import DetailProduct from '../components/DetailProduct';

const Product = ({item}) => {

    const [open, setOpen] = useState(false)

    const openCard = () => {
        if (!open) {
            setOpen(true);
            return;
        }
    }
    const close = () => {
        setOpen(false);
    }

   

  return (
        <div >
         <div class="thing mt-8 z-0"  onClick={()=>openCard()}>
            <div class="">
              <Swiper pagination={true} modules={[Pagination]} className="mySwiper " >
                    <SwiperSlide ><img class="carusel-my" src="../images/img.jpg" alt=""/></SwiperSlide>
                    <SwiperSlide ><img class="carusel-my" src="../images/img.jpg" alt=""/></SwiperSlide>
                    <SwiperSlide ><img class="carusel-my" src="../images/img.jpg" alt=""/></SwiperSlide>
                </Swiper>
            </div>
            <div class="thing_data cursor-pointer">
               <div class="thing_data-wrapper">
                  <h3 class="thing_data__title">{item.title}</h3>
                  <p class="thing_data__description"> {item.description.split(" ").slice(0, 10).join(" ") + "..."}</p>
               </div>
               <div class="thing_data-price">{item.price}$</div>
               
               
            </div>
                {
                open ? (<DetailProduct item={item} onClose={()=>close()} />) : (null)
                }
            </div>
            

         </div>
         
      
  )
}

export default Product