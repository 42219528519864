import { toast } from 'react-hot-toast';
import { useDispatch } from 'react-redux';
import {MdDelete} from "react-icons/md"
import { remove } from '../redux/Slice/CartSlice';
import { useState } from 'react';

const CartItem = ({item, itemIndex}) => {
    
    const dispatch = useDispatch ();

    const removeFromCart = () => {
        dispatch(remove(item.id));
        toast.success("Item removed from cart");
    }
    
    return (
        <div class="card-product">
                <div class="card-product-wrapper">
                  <div class="products-item__img">
                      <img src="../images/img-product.jpg" alt=""/>
                      {/* <img src={item.image} /> */}
                  </div>
                  <div class="products-item-data">
                      <div class="products-item-data__name">{item.title}</div>
                      <div class="products-item-data__price">{item.price} $</div>
                      <div class="products-item-data__size">Размер: XL</div>
                      <div class="product_count">
                        <button class="product_btn__dec"><svg width="14" height="4" viewBox="0 0 14 4" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M1.58333 3.08341H12.4167C13.0147 3.08341 13.5 2.59808 13.5 2.00008C13.5 1.40208 13.0147 0.916748 12.4167 0.916748H1.58333C0.985333 0.916748 0.5 1.40208 0.5 2.00008C0.5 2.59808 0.985333 3.08341 1.58333 3.08341Z"
                                  fill="#393F48" />
                            </svg>
                        </button>
                        <span class="product_count__num">1</span>
                        <button class="product_btn__inc"><svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                              xmlns="http://www.w3.org/2000/svg">
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M1.58333 8.08341H12.4167C13.0147 8.08341 13.5 7.59808 13.5 7.00008C13.5 6.40208 13.0147 5.91675 12.4167 5.91675H1.58333C0.985333 5.91675 0.5 6.40208 0.5 7.00008C0.5 7.59808 0.985333 8.08341 1.58333 8.08341Z"
                                  fill="#393F48" />
                              <path fill-rule="evenodd" clip-rule="evenodd"
                                  d="M8.08341 12.4167V1.58333C8.08341 0.985333 7.59808 0.5 7.00008 0.5C6.40208 0.5 5.91675 0.985333 5.91675 1.58333V12.4167C5.91675 13.0147 6.40208 13.5 7.00008 13.5C7.59808 13.5 8.08341 13.0147 8.08341 12.4167Z"
                                  fill="#393F48" />
                            </svg>
                        </button>
                      </div>
                  </div>
                </div>
                <button class="product-delete"><img src="../images/delete_icon.svg" alt=""/></button>
            </div>
            
       
    )
}

export default CartItem;