import React from 'react'
import { NavLink } from 'react-router-dom'
import {FaShoppingCart} from "react-icons/fa"
import { useSelector } from 'react-redux'

const Navbar = () => {
    
    const {cart} = useSelector((state) => state);

  return (
    <header class="header">
        
        <a class="header__link-logo" href="#">
            <NavLink to="/">
                    <img src="../images/header-logo.svg" alt="логотип"/>
            </NavLink>
            </a>
        <div class="cart" >
            <NavLink  to="/cart"> 
                <img src="../images/basket-transparent.svg" alt="корзина для покупок"/>
            </NavLink>
        </div>
        </header>
        
  )
}

export default Navbar