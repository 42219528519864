import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { add, remove } from '../redux/Slice/CartSlice';
import {toast} from "react-hot-toast";
import { Pagination, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
export default function DetailProduct({ item,onClose }) {
  const {cart} = useSelector( (state) => state);
  const dispatch = useDispatch();
  const addToCart = () => {
    dispatch(add(item));
    toast.success("Добавлен в корзину");
}

const removeFromCart = () => {
    dispatch(remove(item.id));
    toast.error("Удален из корзины");
}

  return (
    

    <div 
      style={{
          top:0, left:0, 
          width: '100vw', 
          height: '100vh', 
          backgroundColor: '#fff',
          overflowY: 'scroll'
        }}
      className="fixed z-10 ">
      
      <section class="screen-2">
      <div class="container-screen">
         <div class="img-container ">
            {/* <img class="img-container__img" src="../images/img4.jpg" alt=""/> */}
            <Swiper pagination={{
                     type: 'fraction',
                  }} navigation={true} modules={[Pagination,Navigation]} className=" z-0 img-container__img" >
               <SwiperSlide ><img class="carusel-my" src="../images/img.jpg" alt=""/></SwiperSlide>
               <SwiperSlide ><img class="carusel-my" src="../images/img.jpg" alt=""/></SwiperSlide>
               <SwiperSlide ><img class="carusel-my" src="../images/img.jpg" alt=""/></SwiperSlide>
            </Swiper>

            <div class="back-btn cursor-pointer" onClick={()=>onClose()}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" data-slot="icon" color="white"><path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12"></path></svg>
            </div>
            <NavLink  to="/cart"> 
              <div class="basket">
                <img src="../images/basket.svg" alt="корзина покупок"/>
              </div>
            </NavLink>
            <div class="size-box">
               <span class="size-box__title">Размер</span>
               <div class="size-box_sizes">
                  <div class="size-box_sizes__item size-box_sizes__item-active">XS</div>
                  <div class="size-box_sizes__item">S</div>
                  <div class="size-box_sizes__item">M</div>
                  <div class="size-box_sizes__item">L</div>
               </div>
            </div>
         </div>
         <div class="product-wrapper">
            <div class="product_title">
               <div class="product_title__name">{item.title}</div>
               <div class="product_title__text">Прекрасные джинсы Momfit</div>
            </div>
            <div class="screen-separator"></div>
            <div class="product_description">
               <p class="product_description__text">
               {item.description}
               </p>
            </div>
            <div class="screen-separator"></div>
            <div class="buy-block">
               <div class="buy-block__price">{item.price}$</div>
               <div class="buy-block_btns-wrapper">
                  

                    {
                      cart.some( (p) => p.id === item.id) ?
                      (
                          <button 
                              className=' buy-block__btn-buy bg-red-700'
                              
                              onClick={removeFromCart}
                          >
                              Удалить
                          </button>
                      ) :
                      (
                          <button
                              className=' buy-block__btn-buy '
                              onClick={addToCart}
                          >
                              Добавить в Корзину
                          </button>


                      )
                  }

                
               </div>
            </div>
         </div>
      </div>
   </section>

    </div>
  )
}