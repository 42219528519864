import React ,{useState, useEffect} from 'react'
import {useSelector} from 'react-redux';
import CartItem from '../components/CartItem';
import { Link } from 'react-router-dom';

const Cart = () => {

  const {cart} = useSelector( (state) => state);
  const [totalAmount, setTotalAmount] = useState(0);
  const [loading, setLoading] = useState(false);
  const LetsPayment = () => {
    setLoading(true)
    const headers = new Headers();
    headers.append('Authorization',  '');
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    
      fetch('https://theosnovatel.com/api/shoping-payment',  {
          method: 'POST',
          headers: headers,
          body: JSON.stringify({ items: cart, amount: totalAmount})
        })
        .then((response) => response.json())
        .then((data) => {
          console.log(data)
          window.location.href = data.url_pay
          setLoading(false)
        })
  }

  useEffect( () => {
    setTotalAmount( cart.reduce( (acc, curr) => acc + curr.price, 0) );
  },[cart])

  return (
  <>
    {
      cart.length > 0 ? 
      (
        <section class="shopping-cart ">
          <div class="products-list">

          {
            cart.map((item,index) => {
              return <CartItem key={item.id} item={item} itemIndex={index} />
            })
          }

            
           
          </div>
          <div class="delivery-wrapper">
            <div class="delivery-recipient">
                <div class="delivery__title">Получатель</div>
                <input class="delivery-recipient__input recipient-name" placeholder="ФИО" type="text" name="name"/>
                <input class="delivery-recipient__input recipient-phone" placeholder="Номер телефона" type="text" name="phone"/>
                <input class="delivery-recipient__input recipient-phone" placeholder="Адрес" type="text" name="address"/>
            </div>
        </div>
          <div class="order-wrapper">
            <div class="order_price">
                <div class="order_price__text">Стоимость</div>
                <div class="order_price__number"> ${totalAmount}</div>
            </div>
            <div class="order_delivery">
                <div class="order_delivery__text">Доставка x {cart.length} шт</div>
                <div class="order_delivery__number">0 $</div>
            </div>
            <div class="order_total">
                <div class="order_total__text">Всего</div>
                <div class="order_total__number"> ${totalAmount} </div>
            </div>
           
            { loading ? (
               <button class="btn-buy main-btn " >Обработка ...</button>
            ) : (
               <button class="btn-buy main-btn" onClick={()=>LetsPayment()}>К оплате</button>
              )}
            <Link to={"/"}> 
           
              <button class="btn-buy main-btn-back mt-2">В Каталог</button>
            </Link>
          </div>
      </section>
      
      ) :
      (
      <div className='min-h-[80vh] flex flex-col items-center justify-center'>
        <div className='mb-5 text-center text-gray-700 font-semibold text-xl mb-2'>
           <p>Корзина Пустая,</p> 
           <p>вернитесь в каталог</p>
          </div>
        <Link to={"/"}>
            <button class="btn-buy main-btn-back mt-2 px-10 tracking-wider">В Каталог</button>
          </Link>
      </div>
      )
    }
  </>
  )
}
  
export default Cart